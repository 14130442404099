.checkbox {
  margin-left: -10px !important;
  :global {
    .MuiSvgIcon-root,
    .MuiIconButton-root {
      // color: var(--color-purple);
      color: var(--color-prussian-blue);
    }
    .PrivateSwitchBase-root-5 {
      padding: 7px;
    }
    .MuiSwitch-track {
      background-color: var(--color-blue-gray) !important;
    }
  }
}
.green {
  :global {
    .MuiSvgIcon-root,
    .MuiIconButton-root {
      color: var(--color-grass) !important;
    }
    .MuiSwitch-track {
      background-color: #019f3e56 !important;
    }
  }
}

.danger {
  :global {
    .MuiSvgIcon-root,
    .MuiIconButton-root {
      color: var(--color-salsa) !important;
    }
    .MuiSwitch-track {
      background-color: #9b7170 !important;
    }
  }
}

.disabled {
  cursor: not-allowed;
  :global {
    .MuiSvgIcon-root {
      color: var(--color-cement);
    }
  }
}
